.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav a {
  text-decoration: none;
  color: aliceblue;
  padding: 10px;
}

nav a.active {
  font-weight: bold;
  background: rgba(255, 255, 255, 0.071);
}

@media only screen and (max-width: 600px) {
  nav a {
    text-decoration: none;
    color: rgb(11, 64, 90);
    padding: 10px;
  }
  
  nav a.active {
    font-weight: bold;
    background: rgba(255, 255, 255, 0.071);
  }
}

.fixed-column {
  position: fixed;
  bottom: 0;
  right: 0;
}

.person_att {
  font-weight: lighter;
}

.hum-logo {
  max-height: 50px;
  padding: 8px;
}

.hum-logo:hover {
  background-color: rgba(255, 255, 255, 0.13);
}

.logo-link {
  display: block;
  margin-left: auto;
  margin-top: 5px;
}

.map-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 42%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

@media only screen and (max-width: 600px) {
  .map-container {
    padding-top: 152%;
  }
}

/* Then style the iframe to fit in the container div with full height and width */
.state-map {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}